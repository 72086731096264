<script lang="ts" setup>
enum TextareaVariants {
  'main',
  'ghost'
}

interface Props {
    placeholder?: string;
    type?: string;
    modelValue?: string;
    disabled?: boolean;
    variant?: keyof typeof TextareaVariants
}

const props = withDefaults(defineProps<Props>(), {
    variant: 'main'
})

const emits = defineEmits(["update:modelValue", "blur"]);

const innerValue = ref("");

const inputChange = (e: Event) => {
  innerValue.value = (e.target as HTMLInputElement).value;
  emits("update:modelValue", innerValue.value);
};

const onBlur = (e: Event) => {
  innerValue.value = (e.target as HTMLInputElement).value;
  emits("blur", innerValue.value);
};
</script>

<template>
  <div class="relative">
    <textarea
        :class="['ui-textarea', `ui-textarea-${variant}`]"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        spellcheck="false"
        @input="inputChange"
        @blur="onBlur"
      />
  </div>
</template>

<style lang="scss" scoped>
.ui-textarea {
  @apply w-full text-base px-4 border border-gray-300 bg-white text-gray-500 rounded-lg outline-none h-[163px] resize-none;
  &::placeholder {
    @apply text-gray-500;
  }
  &-main {
    @apply py-[11px] leading-tight text-sm font-normal bg-gray-50;
  }
  &-ghost {
    @apply py-[13px];
  }
}
</style>
